<template>
    <el-drawer title="批量导入" :visible.sync="isShow" :before-close="onClose" size="1100px" @opened="onOpened">
        <div class="opts">
            <el-upload :before-upload="onUpload" action="http">
                <el-button size="small" type="primary">批量导入</el-button>
                <div slot="tip" class="el-upload__tip" v-if="fileName">{{ fileName }}</div>
            </el-upload>
            <el-link class="template" type="primary" :href="costExcelTemplate">下载导入模板</el-link>
        </div>

        <el-table ref="table" :data="tableData" border stripe @selection-change="onSelectionChange" :key="uuid">
            <el-table-column align="center" type="selection" width="42" :selectable="onItemSelectState" />
            <el-table-column align="center" width="100" prop="项目" label="项目" />
            <el-table-column align="center" width="100" prop="日期" label="日期" />
            <el-table-column prop="投放渠道" label="投放渠道"></el-table-column>
            <el-table-column align="center" width="100" prop="消费" label="消费">
                <template slot-scope="scope">
                    <span>¥{{ scope.row['消费'] ? scope.row['消费'] / 100 : 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" prop="展现" label="展现" />
            <el-table-column align="center" width="90" prop="点击" label="点击" />
            <el-table-column align="center" width="90" prop="投手" label="投手" />
            <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="!scope.row.error" type="primary" effect="dark">正常</el-tag>
                    <el-tag v-else type="danger" effect="dark">{{ scope.row.error }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="提交状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.uploadState === 1" type="success">成功</el-tag>
                    <el-tag v-else-if="scope.row.uploadState === -1" type="danger">冲突</el-tag>
                    <el-tag v-else type="info">等待上报</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <br />
        <div>
            <el-button @click="onSubmit">批量提交</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi'
import { xbProviderCostPut } from '@/js/api/advertApi.js'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import _config from '@/js/config'

export default {
    props: ['isShow', 'providerList', 'projectList'],
    data() {
        return {
            costExcelTemplate: _config.costExcelTemplate,
            fileName: '',
            tableData: [],
            uuid: '',
        }
    },
    created() {
        this.checkedItem = []
    },
    methods: {
        onOpened() {
            this.uploadState = false
        },
        onClose() {
            this.fileName = ''
            this.checkedItem = []
            this.tableData = []
            this.$emit('onClose', this.uploadState)
        },
        onUpload(file) {
            this.fileName = file.name
            const reader = new FileReader()
            reader.onload = async (event) => {
                const data = event.target.result
                const workbook = window.XLSX.read(data, { type: 'binary' })
                // name: val
                const providerMap = {}
                this.providerList.forEach((item) => {
                    providerMap[item.name] = item.providerNo
                })
                const staffNameMap = {}
                const res1 = await getStaffList({})
                res1.data.forEach((staff) => {
                    staffNameMap[staff.name] = staff.id
                })

                const projectMap = {}
                this.projectList.forEach((item) => {
                    projectMap[item.projectName] = item.projectNo
                })

                if (workbook.SheetNames[0]) {
                    const tableData = window.XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
                    tableData.forEach((item) => {
                        item.error = ''
                        item.uploadState = 0

                        if (projectMap[item['项目']]) {
                            item.projectNo = projectMap[item['项目']]
                        } else {
                            item.error = '【项目】异常'
                        }
                        if (item['日期']) {
                            const _n = parseInt(item['日期']) || 0
                            if (_n === 0) {
                                item.error = '【日期】输入异常'
                            } else {
                                item['日期'] = dayjs((_n - 70 * 365 - 19) * 86400 * 1000).format('YYYY-MM-DD')
                            }
                        } else {
                            item.error = '【日期】异常'
                        }
                        if (providerMap[item['投放渠道']]) {
                            item.providerNo = providerMap[item['投放渠道']]
                        } else {
                            item.error = '【渠道】异常'
                        }
                        if (!isNaN(parseInt(item['消费'] * 100))) {
                            item['消费'] = Math.round(item['消费'] * 100)
                        } else {
                            item.error = '【消费】异常'
                        }
                        // 投手
                        if (item['投手']) {
                            item.advertiser = staffNameMap[item['投手']] || null
                            if (!item.advertiser) {
                                item.error = '【投手】不存在'
                            }
                        }
                    })
                    this.tableData = tableData
                } else {
                    this.$message.warning('未查询到数据')
                }
            }
            reader.readAsBinaryString(file)
            this.uuid = uuidv4()
            return false
        },
        onSelectionChange(vals) {
            this.checkedItem = vals
        },
        onItemSelectState(row) {
            return !row.error
        },
        onSubmit() {
            if (this.checkedItem.length === 0) {
                this.$message.warning('请选择提交数据')
            } else {
                this.checkedItem.forEach((item) => {
                    const params = {
                        day: item['日期'],
                        providerNo: item.providerNo,
                        scope: item.scope,
                        projectNo: item.projectNo,
                    }
                    if (item.advertiser) {
                        params.advertiser = item.advertiser
                    }
                    params.cost = item['消费']
                    params.impression = item['展现']
                    params.click = item['点击']
                    xbProviderCostPut(params)
                        .then(() => {
                            item.uploadState = 1
                            this.checkedItem = []
                            this.$refs.table.clearSelection()
                        })
                        .catch(() => {
                            item.uploadState = -1
                            this.checkedItem = []
                            this.$refs.table.clearSelection()
                        })
                })
                this.uploadState = true
            }
        },
    },
}
</script>
<style lang="less" scoped>
.opts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .template {
        margin: 0 0 4px 20px;
        font-size: 13px;
    }
}
</style>
