<template>
    <el-dialog title="编辑数据" :visible.sync="isShow" width="420px" :before-close="onClose" @opened="onOpened">
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="80px">
            <el-form-item label="项目" prop="projectNo">
                <ProjectSelect :defaultValue="formData.projectNo" @change="(val) => (formData.projectNo = val)" />
            </el-form-item>
            <el-form-item label="日期" prop="day">
                <el-date-picker value-format="yyyy-MM-dd" v-model="formData.day" type="date" placeholder="选择日期" />
            </el-form-item>
            <el-form-item label="投放渠道" prop="providerNo">
                <el-select v-model="formData.providerNo" filterable placeholder="请选择">
                    <el-option
                        v-for="item in providerList"
                        :key="item.providerNo"
                        :label="item.name"
                        :value="item.providerNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="消费" prop="costNum">
                <el-input-number
                    :controls="false"
                    :precision="2"
                    placeholder="请输入"
                    v-model.trim="formData.costNum"
                />
            </el-form-item>
            <el-form-item label="展现" prop="impression">
                <el-input-number
                    :precision="0"
                    :min="0"
                    :controls="false"
                    placeholder="请输入"
                    v-model.trim="formData.impression"
                />
            </el-form-item>
            <el-form-item label="点击" prop="click">
                <el-input-number
                    :precision="0"
                    :min="0"
                    :controls="false"
                    placeholder="请输入"
                    v-model.trim="formData.click"
                />
            </el-form-item>
            <el-form-item label="投手" prop="advertiser">
                <StaffSelect :defaultValue="formData.advertiser" :clearable="true" @change="onStaffChange" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmitForm">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import StaffSelect from '@/views/components/StaffSelect'
import { xbProviderDaySummaryAdd, xbProviderDaySummaryMod } from '@/js/api/advertApi.js'
import { numToCent } from '@/js/numTools.js'

export default {
    components: { StaffSelect, ProjectSelect },
    props: ['isShow', 'activeItem', 'projectNo', 'providerList'],
    data() {
        return {
            formData: null,
            rules: {
                day: [{ required: true, message: '请选择', trigger: 'change' }],
                providerNo: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            defaultValue: 0,
        }
    },
    methods: {
        onOpened() {
            let formData = {
                projectNo: this.projectNo,
                day: '',
                providerNo: '',
                budgetOppo: 0,
                budgetCost: 0,
                budgetCostNum: 0,
                cost: 0,
                costNum: 0,
                impression: 0,
                click: 0,
                advertiser: '',
            }
            if (this.activeItem) {
                formData = {
                    ...formData,
                    ...this.activeItem,
                    costNum: parseInt(this.activeItem.cost) / 100,
                    budgetCostNum: parseInt(this.activeItem.budgetCost) / 100,
                }
            }
            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onStaffChange(val) {
            this.formData.advertiser = val
        },
        onSubmitForm() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.formData.cost = numToCent(this.formData.costNum)
                    this.formData.budgetCost = numToCent(this.formData.budgetCostNum)
                    if (this.formData.id) {
                        xbProviderDaySummaryMod({ ...this.formData }).then(() => {
                            this.$message.success('修改成功')
                            this.onClose()
                            this.$emit('onClose', true)
                        })
                    } else {
                        xbProviderDaySummaryAdd({ ...this.formData })
                            .then(() => {
                                this.$message.success('添加成功')
                                this.onClose()
                                this.$emit('onClose', true)
                            })
                            .catch((err) => {
                                this.$message.warning(err.data.message)
                            })
                    }
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-date-editor,
.el-select {
    width: 100%;
}
</style>
