<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @projectList="onProjectListChange" @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">投放渠道</span>
                    <span class="condition_content">
                        <el-cascader
                            v-model="providerNos"
                            :options="providerCascader"
                            placeholder="投放渠道"
                            filterable
                            clearable
                            :props="{ expandTrigger: 'hover' }"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">日期</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="addTimes"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <el-button
                    class="float_left_btn"
                    type="primary"
                    v-if="P(1010502)"
                    plain
                    @click="onShowEditAdPlatform(null)"
                >
                    新增成本
                </el-button>
                <el-button class="float_left_btn" @click="isShowEditBatchUploadForm = true" v-if="P(1010503)">
                    批量上传
                </el-button>
                <ExportFile
                    class="float_left_btn"
                    host="hunter"
                    path="/xbProviderCost/export"
                    @before-export="onBeforeExport"
                    v-if="P(1010501)"
                />
            </div>
        </div>
        <el-table :data="tableData" highlight-current-row border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column label="日期">
                <template slot-scope="scope">
                    <span>{{ scope.row.day.substr(0, 10) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="providerNo" label="投放渠道">
                <template slot-scope="scope">
                    <span>{{ providerMap ? providerMap[scope.row.providerNo] : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="消费">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.cost / 100" />
                </template>
            </el-table-column>
            <el-table-column prop="impression" label="展现">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.impression" :float="0" />
                </template>
            </el-table-column>
            <el-table-column prop="click" label="点击">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.click" :float="0" />
                </template>
            </el-table-column>
            <el-table-column label="投手">
                <template slot-scope="scope">
                    <StaffName :staff="scope.row.staff" />
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <el-link v-if="P(1010502)" @click="onShowEditAdPlatform(scope.row)" type="primary">编辑</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />
        <EditAdPlatform
            :providerList="providerList"
            @onClose="onCloseEditAdPlatform"
            :isShow.sync="isShowEditAdPlatform"
            :activeItem="activeItem"
        />
        <EditBatchUploadForm
            :projectList="projectList"
            :providerList="providerList"
            :isShow.sync="isShowEditBatchUploadForm"
            @onClose="onCloseEditBatchUploadForm"
        />
    </div>
</template>

<script>
import { getStaffList } from '@/js/api/staffApi'
import { xbProviderDaySummaryList, getProviderCategoryData } from '@/js/api/advertApi'
import { filterQueryEmpty, isEmptyArray, listObjectField, listFormatMap } from '@/js/utils.js'
import EditAdPlatform from './components/cost/EditAdPlatform.vue'
import EditBatchUploadForm from './components/cost/EditBatchUploadForm.vue'
import ProjectSelect from '@/views/components/ProjectSelect'

export default {
    data() {
        return {
            projectList: [],
            projectNo: '',
            providerCascader: [],
            providerList: [],
            providerMap: null,
            providerNos: [],
            addTimes: [],

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            isShowEditAdPlatform: false,
            isShowEditBatchUploadForm: false,
            activeItem: null,
        }
    },
    components: { EditAdPlatform, EditBatchUploadForm, ProjectSelect },
    created() {
        this.getProviderCategoryDataApi()
    },
    methods: {
        onReset() {
            this.providerNos = []
            this.addTimes = []

            this.onPaginationChange('page', 1)
        },

        onProjectListChange(val) {
            this.projectList = val
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onBeforeExport(cb) {
            cb(this.getParams())
        },
        onShowEditAdPlatform(item) {
            this.activeItem = item
            this.isShowEditAdPlatform = true
        },
        onCloseEditAdPlatform(state) {
            this.isShowEditAdPlatform = false
            this.activeItem = null
            if (state) this.xbProviderDaySummaryListApi()
        },
        onCloseEditBatchUploadForm(status) {
            this.isShowEditBatchUploadForm = false
            if (status) this.xbProviderDaySummaryListApi()
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.xbProviderDaySummaryListApi)
        },

        getParams() {
            let params = { projectNo: this.projectNo }
            params.providerNo = !isEmptyArray(this.providerNos) ? this.providerNos[1] : null
            if (!isEmptyArray(this.addTimes)) {
                params.timeStart = this.addTimes[0]
                params.timeEnd = this.addTimes[1]
            }
            params = filterQueryEmpty(params)
            return params
        },

        async xbProviderDaySummaryListApi() {
            let params = {
                orderBy: '-day',
                page: this.page,
                size: this.pageSize,
                showPage: true,
                ...this.getParams(),
            }

            params = filterQueryEmpty(params)
            const res1 = await xbProviderDaySummaryList(params)
            if (res1.data.list.length !== 0) {
                const _staffIds = listObjectField({ list: res1.data.list, key: 'advertiser' })
                const res2 = await getStaffList({ staffIds: _staffIds })
                const _map = listFormatMap({ list: res2.data, key: 'id' })
                res1.data.list.forEach((i) => {
                    i.staff = _map[i.advertiser] || {}
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerList = res.providerList
                this.providerMap = res.providerMap
                this.providerCascader = res.providerCascader
            })
        },
    },
}
</script>
<style lang="less" scoped>
.tab_day {
    height: 100%;
    display: flex;
    flex-direction: column;
    .query_header {
        flex-shrink: 0;
    }
    /deep/ .el-table {
        .el-table__body-wrapper {
            height: calc(100% - 40px);
            overflow-y: auto;
        }
    }
}
</style>
